<template>
  <div class="openbox">
    <div class="goout" @click="$router.back()">
      <img src="@/assets/images/openbox/goout.png" alt />
    </div>
    <div class="openbox-main">
      <div class="openbox-title">
        <div class="closem d-flex ai-center" v-show="how" @click="shoW">
          <div class="closema">
            <img
              src="@/assets/images/openbox/openm.png"
              width="25px"
              height="25px"
              alt
            />
          </div>
          <div class="closemt color-white">音效已开启</div>
        </div>
        <div class="closem d-flex ai-center" v-show="!how" @click="shoW">
          <div class="closema">
            <img
              src="@/assets/images/openbox/closem.png"
              width="25px"
              height="25px"
              alt
            />
          </div>
          <div class="closemt color-white">音效已关闭</div>
        </div>
        <div
          class="closem d-flex ai-center"
          style="margin-top: 4px"
          v-show="isShow"
          @click="show"
        >
          <div class="closema">
            <img
              src="@/assets/images/openbox/opena.png"
              width="25px"
              height="25px"
              alt
            />
          </div>
          <div class="closemt color-white">动画已开启</div>
        </div>
        <div
          class="closem d-flex ai-center"
          style="margin-top: 4px"
          v-show="!isShow"
          @click="show"
        >
          <div class="closema">
            <img
              src="@/assets/images/openbox/closea.png"
              width="25px"
              height="25px"
              alt
            />
          </div>
          <div class="closemt color-white">动画已关闭</div>
        </div>
      </div>
      <div class="gunboxbg">
        <img :src="weaponImg" alt class="openbox-img" />
      </div>
      <div class="boxname">
        <span>{{ weaponTxt }}</span>
      </div>
      <!-- 开箱个数 -->
      <div class="amount">
        <div @click="first" :class="status == '1' ? 'active ' : 'actived '">
          x1
        </div>
        <div @click="second" :class="status == '2' ? 'active ' : 'actived '">
          x2
        </div>
        <div @click="third" :class="status == '3' ? 'active ' : 'actived '">
          x3
        </div>
        <div @click="forth" :class="status == '4' ? 'active ' : 'actived '">
          x4
        </div>
        <div @click="fifth" :class="status == '5' ? 'active ' : 'actived '">
          x5
        </div>
      </div>
      <!-- 开箱 -->
      <div class="open" @click="openAnimation" v-show="isLogin == 2">
        开启
        <span>{{ weaponPrice }}</span>
      </div>
      <div class="open" @click="demo" v-show="isLogin == 1">试玩</div>
      <!-- 箱内物品和最近掉落 -->
      <div class="goods-type">
        <div @click="boxgoods" :class="good == 2 ? '' : 'boxs'">箱内物品</div>
        <div @click="falling" :class="good == 1 ? '' : 'boxs'">最近掉落</div>
      </div>
      <!-- 箱内物品 -->
      <div class="newfalling" v-show="good == 1">
        <div
          v-for="(item, index) in boxcontain"
          :key="index"
          class="fall-header"
          :class="`weapon-${item.color}`"
        >
          <div class="fall-item d-flex j-a">
            <div class="d-flex" style="align-items: center">
              <img src="@/assets/images/pk/jinbi.png" alt />
              <div class="f-13">{{ item.openPrice }}</div>
            </div>
            <div class="showodds f-13">{{ item.showProbability }}</div>
          </div>
          <img :src="item.img" alt class="fall-img" />
          <div class="fall-title txt">{{ item.abrasion }}</div>
          <div class="fall-title">{{ item.name }}</div>
        </div>
      </div>
      <!-- 最近掉落 -->
      <div class="newfalling" v-show="good == 2">
        <div
          v-for="(item, index) in recentFall"
          :key="index"
          class="fall-header"
          :class="`weapon-${item.ocolor}`"
        >
          <div class="fall-item" style="margin-left: 20px">
            <img
              src="@/assets/images/pk/jinbi.png"
              alt
              style="margin-left: 4px; margin-right: 4px"
            />
            <div>{{ item.open_price }}</div>
          </div>
          <img :src="item.img" alt class="fall-img" />
          <div class="fall-title txt">{{ item.marketHashName }}</div>
          <div class="fall-title">{{ item.name }}</div>
        </div>
      </div>
      <div class="bg" v-show="cartoon == true">
        <!-- 开一个箱子 -->
        <div class="open-animation1" v-show="cartoon == true && status == 1">
          <div class="main">
            <img src="@/assets/images/openbox/left.png" alt class="zhizhen1" />
            <img src="@/assets/images/openbox/right.png" alt class="zhizhen2" />
            <img src="@/assets/images/openbox/heng.png" alt class="zhizhen3" />
            <div class="main-item">
              <div
                class="item-main animation1"
                v-for="(item, index) in prizes1"
                :key="index"
                :class="`weapon-${item.color}`"
              >
                <!-- <div class="wh">
                  <img
                    src="@/assets/images/pk/jinbi.png"
                    width="15px"
                    height="15px"
                    alt
                  />
                  <div class="f-13">{{ item.openPrice }}</div>
                </div>-->
                <img :src="item.img" alt class="wmi" />
                <div class="iab">{{ item.abrasion }}</div>
                <div class="iab">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 开两个箱子 -->
        <div class="open-animation2" v-show="cartoon == true && status == 2">
          <div class="main">
            <img src="@/assets/images/openbox/left.png" alt class="zhizhen1" />
            <img src="@/assets/images/openbox/right.png" alt class="zhizhen2" />
            <img src="@/assets/images/openbox/heng.png" alt class="zhizhen3" />
            <div class="main-item">
              <div
                class="item-main animation21"
                v-for="(item, index) in prizes1"
                :key="index"
                :class="`weapon-${item.color}`"
              >
                <!-- <div class="wh">
                  <img src="@/assets/images/pk/jinbi.png" alt />
                  <div class="f-13">{{ item.openPrice }}</div>
                </div>-->
                <img :src="item.img" alt class="wmi" />
                <div class="iab">{{ item.abrasion }}</div>
                <div class="iab">{{ item.name }}</div>
              </div>
            </div>
            <div class="main-item">
              <div
                class="item-main animation22"
                v-for="(item, index) in prizes2"
                :key="index"
                :class="`weapon-${item.color}`"
              >
                <!-- <div class="wh">
                  <img src="@/assets/images/pk/jinbi.png" alt />
                  <div class="f-13">{{ item.openPrice }}</div>
                </div>-->
                <img :src="item.img" alt class="wmi" />
                <div class="iab">{{ item.abrasion }}</div>
                <div class="iab">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 开三个箱子 -->
        <div class="open-animation3" v-show="cartoon == true && status == 3">
          <div class="main">
            <img src="@/assets/images/openbox/left.png" alt class="zhizhen1" />
            <img src="@/assets/images/openbox/right.png" alt class="zhizhen2" />
            <img src="@/assets/images/openbox/heng.png" alt class="zhizhen3" />
            <div class="main-item">
              <div
                class="item-main animation31"
                v-for="(item, index) in prizes1"
                :key="index"
                :class="`weapon-${item.color}`"
              >
                <!-- <div class="wh">
                  <img src="@/assets/images/pk/jinbi.png" alt />
                  <div class="f-13">{{ item.openPrice }}</div>
                </div>-->
                <img :src="item.img" alt class="wmi" />
                <div class="iab">{{ item.abrasion }}</div>
                <div class="iab">{{ item.name }}</div>
              </div>
            </div>
            <div class="main-item">
              <div
                class="item-main animation32"
                v-for="(item, index) in prizes2"
                :key="index"
                :class="`weapon-${item.color}`"
              >
                <!-- <div class="wh">
                  <img src="@/assets/images/pk/jinbi.png" alt />
                  <div class="f-13">{{ item.openPrice }}</div>
                </div>-->
                <img :src="item.img" alt class="wmi" />
                <div class="iab">{{ item.abrasion }}</div>
                <div class="iab">{{ item.name }}</div>
              </div>
            </div>
            <div class="main-item">
              <div
                class="item-main animation33"
                v-for="(item, index) in prizes3"
                :key="index"
                :class="`weapon-${item.color}`"
              >
                <!-- <div class="wh">
                  <img src="@/assets/images/pk/jinbi.png" alt />
                  <div class="f-13">{{ item.openPrice }}</div>
                </div>-->
                <img :src="item.img" alt class="wmi" />
                <div class="iab">{{ item.abrasion }}</div>
                <div class="iab">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 开四个箱子 -->
        <div class="open-animation4" v-show="cartoon == true && status == 4">
          <div class="main">
            <img src="@/assets/images/openbox/left.png" alt class="zhizhen1" />
            <img src="@/assets/images/openbox/right.png" alt class="zhizhen2" />
            <img src="@/assets/images/openbox/heng.png" alt class="zhizhen3" />
            <div class="main-item">
              <div
                class="item-main animation41"
                v-for="(item, index) in prizes1"
                :key="index"
                :class="`weapon-${item.color}`"
              >
                <!-- <div class="wh">
                  <img src="@/assets/images/pk/jinbi.png" alt />
                  <div class="f-13">{{ item.openPrice }}</div>
                </div>-->
                <img :src="item.img" alt class="wmi" />
                <div class="iab">{{ item.abrasion }}</div>
                <div class="iab">{{ item.name }}</div>
              </div>
            </div>
            <div class="main-item">
              <div
                class="item-main animation42"
                v-for="(item, index) in prizes2"
                :key="index"
                :class="`weapon-${item.color}`"
              >
                <!-- <div class="wh">
                  <img src="@/assets/images/pk/jinbi.png" alt />
                  <div class="f-13">{{ item.openPrice }}</div>
                </div>-->
                <img :src="item.img" alt class="wmi" />
                <div class="iab">{{ item.abrasion }}</div>
                <div class="iab">{{ item.name }}</div>
              </div>
            </div>
            <div class="main-item">
              <div
                class="item-main animation43"
                v-for="(item, index) in prizes3"
                :key="index"
                :class="`weapon-${item.color}`"
              >
                <!-- <div class="wh">
                  <img src="@/assets/images/pk/jinbi.png" alt />
                  <div class="f-13">{{ item.openPrice }}</div>
                </div>-->
                <img :src="item.img" alt class="wmi" />
                <div class="iab">{{ item.abrasion }}</div>
                <div class="iab">{{ item.name }}</div>
              </div>
            </div>
            <div class="main-item">
              <div
                class="item-main animation44"
                v-for="(item, index) in prizes4"
                :key="index"
                :class="`weapon-${item.color}`"
              >
                <!-- <div class="wh">
                  <img src="@/assets/images/pk/jinbi.png" alt />
                  <div class="f-13">{{ item.openPrice }}</div>
                </div>-->
                <img :src="item.img" alt class="wmi" />
                <div class="iab">{{ item.abrasion }}</div>
                <div class="iab">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 开五个箱子 -->
        <div class="open-animation5" v-show="cartoon == true && status == 5">
          <div class="main">
            <img src="@/assets/images/openbox/left.png" alt class="zhizhen1" />
            <img src="@/assets/images/openbox/right.png" alt class="zhizhen2" />
            <img src="@/assets/images/openbox/heng.png" alt class="zhizhen3" />
            <div class="main-item">
              <div
                class="item-main animation51"
                v-for="(item, index) in prizes1"
                :key="index"
                :class="`weapon-${item.color}`"
              >
                <!-- <div class="wh">
                  <img src="@/assets/images/pk/jinbi.png" alt />
                  <div class="f-13">{{ item.openPrice }}</div>
                </div>-->
                <img :src="item.img" alt class="wmi" />
                <div class="iab">{{ item.abrasion }}</div>
                <div class="iab">{{ item.name }}</div>
              </div>
            </div>
            <div class="main-item">
              <div
                class="item-main animation52"
                v-for="(item, index) in prizes2"
                :key="index"
                :class="`weapon-${item.color}`"
              >
                <!-- <div class="wh">
                  <img src="@/assets/images/pk/jinbi.png" alt />
                  <div class="f-13">{{ item.openPrice }}</div>
                </div>-->
                <img :src="item.img" alt class="wmi" />
                <div class="iab">{{ item.abrasion }}</div>
                <div class="iab">{{ item.name }}</div>
              </div>
            </div>
            <div class="main-item">
              <div
                class="item-main animation53"
                v-for="(item, index) in prizes3"
                :key="index"
                :class="`weapon-${item.color}`"
              >
                <!-- <div class="wh">
                  <img src="@/assets/images/pk/jinbi.png" alt />
                  <div>{{ item.openPrice }}</div>
                </div>-->
                <img :src="item.img" alt class="wmi" />
                <div class="iab">{{ item.abrasion }}</div>
                <div class="iab">{{ item.name }}</div>
              </div>
            </div>
            <div class="main-item">
              <div
                class="item-main animation54"
                v-for="(item, index) in prizes4"
                :key="index"
                :class="`weapon-${item.color}`"
              >
                <!-- <div class="wh">
                  <img src="@/assets/images/pk/jinbi.png" alt />
                  <div>{{ item.openPrice }}</div>
                </div>-->
                <img :src="item.img" alt class="wmi" />
                <div class="iab">{{ item.abrasion }}</div>
                <div class="iab">{{ item.name }}</div>
              </div>
            </div>
            <div class="main-item">
              <div
                class="item-main animation55"
                v-for="(item, index) in prizes5"
                :key="index"
                :class="`weapon-${item.color}`"
              >
                <!-- <div class="wh">
                  <img src="@/assets/images/pk/jinbi.png" alt />
                  <div>{{ item.openPrice }}</div>
                </div>-->
                <img :src="item.img" alt class="wmi" />
                <div class="iab">{{ item.abrasion }}</div>
                <div class="iab">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 中奖 -->
      <div class="bg" v-show="prize == true">
        <div class="congratulation">
          <div class="c-main">
            <div class="cnm">
              <!-- 未登录中奖 -->
              <div
                class="c-item"
                v-for="(item, index) in prizes"
                :class="`weapon-${item.ocolor}`"
                :key="index + 'za'"
                v-show="isLogin == 1"
              >
                <div class="c-item-header">
                  <img src="@/assets/images/pk/jinbi.png" alt />
                  <div>{{ item.openPrice }}</div>
                </div>
                <img :src="item.img" alt class="c-item-middle" />
                <div class="c-item-text">{{ item.name }}</div>
                <div class="c-item-title">{{ item.abrasion }}</div>
              </div>
              <!-- 已登录中奖 -->
              <div
                class="c-item"
                v-show="isLogin == 2"
                v-for="(item, index) in prizes"
                :class="`weapon-${item.ocolor}`"
                :key="index"
              >
                <div class="c-item-header">
                  <div class="d-flex" style="align-items: center">
                    <img src="@/assets/images/pk/jinbi.png" alt />
                    {{ item.opeice }}
                  </div>
                  <div @click="decompose(index)">分解</div>
                </div>
                <img :src="item.oimg" alt class="c-item-middle" />
                <div class="c-item-text">{{ item.oname }}</div>
                <div class="c-item-title">{{ item.abrasion }}</div>
              </div>
            </div>
          </div>
          <div class="c-footer">
            <div class="fang" @click="fang">放入背包</div>
            <div class="fen" @click="confirm">{{ totalPrice }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getBoxDetail,
  boxContain,
  newFallAPI,
  openBoxAPI,
  getuserbean,
  decomposeWeaponAPI,
  getUserInfo,
  allDecomposeAPI,
  IsRecharge,
} from "../../api";
import { getToken } from "../../api/cookie";

export default {
  inject: ["reload"],
  data() {
    return {
      weaponTxt: "",
      weaponImg: "",
      weaponPrice: 0,
      status: "1",
      isLogin: 1,
      values: 0,
      good: 1,
      recentFall: [],
      boxcontain: [],
      boxcontainLength: 0,
      cartoon: false,
      imgs: [],
      prize: false,
      //奖品
      prizes: [],
      logInEd: false,
      noLogIn: true,
      userGold: 0,
      prizes1: [],
      prizes2: [],
      prizes3: [],
      prizes4: [],
      prizes5: [],
      isshow: true,
      screenWidth: null,
      weaponID: [],
      totalPrice: 0,
      isShow: true,
      how: true,
    };
  },

  methods: {
    first() {
      this.status = "1";
      this.weaponPrice = this.values;
      this.weaponPrice = this.weaponPrice * 1;
    },
    //未登录，只能开一个
    second() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.status = "2";
        this.weaponPrice = this.values;
        this.weaponPrice = this.weaponPrice * 2;
      }
    },
    third() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.status = "3";
        this.weaponPrice = this.values;
        this.weaponPrice = Number((this.weaponPrice * 3).toFixed(2));
      }
    },
    forth() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.status = "4";
        this.weaponPrice = this.values;
        this.weaponPrice = this.weaponPrice * 4;
      }
    },
    fifth() {
      let token = getToken();
      if (token == undefined) {
        this.$bus.$emit("unlogin", true);
      } else {
        this.status = "5";
        this.weaponPrice = this.values;
        this.weaponPrice = Number((this.weaponPrice * 5).toFixed(2));
      }
    },
    boxgoods() {
      this.good = 1;
    },
    falling() {
      this.good = 2;
    },
    //开启
    openAnimation() {
      this.$bus.$emit("unshow", false);
      IsRecharge().then((res) => {
        console.log(res.data.data);
        if (res.data.data == true) {
          getuserbean().then((res) => {
            let userbaen = res.data.data;
            if (userbaen >= this.weaponPrice) {
              if (this.isShow == true) {
                //是否开启声音
                if (this.how) {
                  this.cartoon = true;
                  document.getElementById("open").play();
                  //打乱顺序
                  while (this.prizes1.length < 60) {
                    let b = this.boxcontain.length;
                    let a = Math.floor(Math.random() * b);
                    this.prizes1.push(this.boxcontain[a]);
                  }
                  let i = this.prizes1.length;
                  while (i) {
                    let j = Math.floor(Math.random() * i--);
                    [this.prizes1[j], this.prizes1[i]] = [
                      this.prizes1[i],
                      this.prizes1[j],
                    ];
                  }
                  // console.log(this.prizes1);
                  while (this.prizes2.length < 60) {
                    let b = this.boxcontain.length;
                    let a = Math.floor(Math.random() * b);
                    this.prizes2.push(this.boxcontain[a]);
                  }
                  let c = this.prizes2.length;
                  while (c) {
                    let d = Math.floor(Math.random() * c--);
                    [this.prizes2[d], this.prizes2[c]] = [
                      this.prizes2[c],
                      this.prizes2[d],
                    ];
                  }
                  while (this.prizes3.length < 60) {
                    let b = this.boxcontain.length;
                    let a = Math.floor(Math.random() * b);
                    this.prizes3.push(this.boxcontain[a]);
                  }
                  let e = this.prizes3.length;
                  while (e) {
                    let f = Math.floor(Math.random() * e--);
                    [this.prizes3[f], this.prizes3[e]] = [
                      this.prizes3[e],
                      this.prizes3[f],
                    ];
                  }
                  while (this.prizes4.length < 60) {
                    let b = this.boxcontain.length;
                    let a = Math.floor(Math.random() * b);
                    this.prizes4.push(this.boxcontain[a]);
                  }
                  let g = this.prizes4.length;
                  while (g) {
                    let h = Math.floor(Math.random() * g--);
                    [this.prizes4[h], this.prizes4[g]] = [
                      this.prizes4[g],
                      this.prizes4[h],
                    ];
                  }
                  while (this.prizes5.length < 60) {
                    let b = this.boxcontain.length;
                    let a = Math.floor(Math.random() * b);
                    this.prizes5.push(this.boxcontain[a]);
                  }
                  let m = this.prizes5.length;
                  while (m) {
                    let n = Math.floor(Math.random() * m--);
                    [this.prizes5[n], this.prizes5[m]] = [
                      this.prizes5[m],
                      this.prizes5[n],
                    ];
                  }
                  let options = {
                    id: Number(this.$route.params.id),
                    count: Number(this.status),
                  };
                  setTimeout(() => {
                    this.cartoon = false;
                    this.prize = true;
                  }, 6000);
                  // 调用开箱接口;
                  openBoxAPI(options).then((res) => {
                    // console.log(res);
                    this.totalPrice = 0;
                    this.weaponID = [];
                    this.prizes = [];
                    for (var i = 0; i < Number(this.status); i++) {
                      this.prizes.push(res.data.data[i].ttBoxRecord);
                      this.weaponID.push(res.data.data[i].ttBoxRecord.recordId);
                      this.totalPrice += res.data.data[i].ttBoxRecord.opeice;
                    }
                    this.totalPrice = this.totalPrice.toFixed(2);
                    //开一个
                    if (this.status == "1") {
                      let bid = this.prizes[0].oid;
                      //根据 已知id 获取在数组中的位置(index)；
                      let index = this.boxcontain.findIndex(
                        (item) => item.id == bid
                      );
                      //交换位置
                      this.prizes1[18] = this.boxcontain[index];
                    }
                    //开两个
                    else if (this.status == "2") {
                      for (let i = 0; i < 2; i++) {
                        if (i == 0) {
                          let bid = this.prizes[0].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes1[18] = this.boxcontain[index];
                        } else if (i == 1) {
                          let bid2 = this.prizes[1].oid;
                          let index2 = this.boxcontain.findIndex(
                            (item) => item.id == bid2
                          );
                          this.prizes2[18] = this.boxcontain[index2];
                        }
                      }
                    }
                    //开三个
                    else if (this.status == "3") {
                      for (let i = 0; i < 3; i++) {
                        if (i == 0) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes1[18] = this.boxcontain[index];
                        } else if (i == 1) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes2[18] = this.boxcontain[index];
                        } else if (i == 2) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes3[18] = this.boxcontain[index];
                        }
                      }
                    }
                    //开四个
                    else if (this.status == "4") {
                      for (let i = 0; i < 4; i++) {
                        if (i == 0) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes1[18] = this.boxcontain[index];
                        } else if (i == 1) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes2[18] = this.boxcontain[index];
                        } else if (i == 2) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes3[18] = this.boxcontain[index];
                        } else if (i == 3) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes4[18] = this.boxcontain[index];
                        }
                      }
                    }
                    //开五个
                    else if (this.status == "5") {
                      for (let i = 0; i < 5; i++) {
                        if (i == 0) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes1[18] = this.boxcontain[index];
                        } else if (i == 1) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes2[18] = this.boxcontain[index];
                        } else if (i == 2) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes3[18] = this.boxcontain[index];
                        } else if (i == 3) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes4[18] = this.boxcontain[index];
                        } else if (i == 4) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes5[18] = this.boxcontain[index];
                        }
                      }
                    }
                  });
                } else {
                  this.cartoon = true;
                  //打乱顺序
                  while (this.prizes1.length < 60) {
                    let b = this.boxcontain.length;
                    let a = Math.floor(Math.random() * b);
                    this.prizes1.push(this.boxcontain[a]);
                  }
                  let i = this.prizes1.length;
                  while (i) {
                    let j = Math.floor(Math.random() * i--);
                    [this.prizes1[j], this.prizes1[i]] = [
                      this.prizes1[i],
                      this.prizes1[j],
                    ];
                  }
                  while (this.prizes2.length < 60) {
                    let b = this.boxcontain.length;
                    let a = Math.floor(Math.random() * b);
                    this.prizes2.push(this.boxcontain[a]);
                  }
                  let c = this.prizes2.length;
                  while (c) {
                    let d = Math.floor(Math.random() * c--);
                    [this.prizes2[d], this.prizes2[c]] = [
                      this.prizes2[c],
                      this.prizes2[d],
                    ];
                  }
                  while (this.prizes3.length < 60) {
                    let b = this.boxcontain.length;
                    let a = Math.floor(Math.random() * b);
                    this.prizes3.push(this.boxcontain[a]);
                  }
                  let e = this.prizes3.length;
                  while (e) {
                    let f = Math.floor(Math.random() * e--);
                    [this.prizes3[f], this.prizes3[e]] = [
                      this.prizes3[e],
                      this.prizes3[f],
                    ];
                  }
                  while (this.prizes4.length < 60) {
                    let b = this.boxcontain.length;
                    let a = Math.floor(Math.random() * b);
                    this.prizes4.push(this.boxcontain[a]);
                  }
                  let g = this.prizes4.length;
                  while (g) {
                    let h = Math.floor(Math.random() * g--);
                    [this.prizes4[h], this.prizes4[g]] = [
                      this.prizes4[g],
                      this.prizes4[h],
                    ];
                  }
                  while (this.prizes5.length < 60) {
                    let b = this.boxcontain.length;
                    let a = Math.floor(Math.random() * b);
                    this.prizes5.push(this.boxcontain[a]);
                  }
                  let m = this.prizes5.length;
                  while (m) {
                    let n = Math.floor(Math.random() * m--);
                    [this.prizes5[n], this.prizes5[m]] = [
                      this.prizes5[m],
                      this.prizes5[n],
                    ];
                  }
                  let options = {
                    id: Number(this.$route.params.id),
                    count: Number(this.status),
                  };
                  setTimeout(() => {
                    this.cartoon = false;
                    this.prize = true;
                  }, 6000);
                  //调用开箱接口
                  openBoxAPI(options).then((res) => {
                    this.totalPrice = 0;
                    this.weaponID = [];
                    this.prizes = [];
                    for (var i = 0; i < Number(this.status); i++) {
                      this.prizes.push(res.data.data[i].ttBoxRecord);
                      this.weaponID.push(res.data.data[i].ttBoxRecord.recordId);
                      this.totalPrice += res.data.data[i].ttBoxRecord.opeice;
                    }
                    this.totalPrice = this.totalPrice.toFixed(2);
                    //开一个
                    if (this.status == "1") {
                      let bid = this.prizes[0].oid;
                      //根据 已知id 获取在数组中的位置(index)；
                      let index = this.boxcontain.findIndex(
                        (item) => item.id == bid
                      );
                      //交换位置
                      this.prizes1[18] = this.boxcontain[index];
                    }
                    //开两个
                    else if (this.status == "2") {
                      for (let i = 0; i < 2; i++) {
                        if (i == 0) {
                          let bid = this.prizes[0].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes1[18] = this.boxcontain[index];
                        } else if (i == 1) {
                          let bid2 = this.prizes[1].oid;
                          let index2 = this.boxcontain.findIndex(
                            (item) => item.id == bid2
                          );
                          this.prizes2[18] = this.boxcontain[index2];
                        }
                      }
                    }
                    //开三个
                    else if (this.status == "3") {
                      for (let i = 0; i < 3; i++) {
                        if (i == 0) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes1[18] = this.boxcontain[index];
                        } else if (i == 1) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes2[18] = this.boxcontain[index];
                        } else if (i == 2) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes3[18] = this.boxcontain[index];
                        }
                      }
                    }
                    //开四个
                    else if (this.status == "4") {
                      for (let i = 0; i < 4; i++) {
                        if (i == 0) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes1[18] = this.boxcontain[index];
                        } else if (i == 1) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes2[18] = this.boxcontain[index];
                        } else if (i == 2) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes3[18] = this.boxcontain[index];
                        } else if (i == 3) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes4[18] = this.boxcontain[index];
                        }
                      }
                    }
                    //开五个
                    else if (this.status == "5") {
                      for (let i = 0; i < 5; i++) {
                        if (i == 0) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes1[18] = this.boxcontain[index];
                        } else if (i == 1) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes2[18] = this.boxcontain[index];
                        } else if (i == 2) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes3[18] = this.boxcontain[index];
                        } else if (i == 3) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes4[18] = this.boxcontain[index];
                        } else if (i == 4) {
                          let bid = this.prizes[i].oid;
                          let index = this.boxcontain.findIndex(
                            (item) => item.id == bid
                          );
                          this.prizes5[18] = this.boxcontain[index];
                        }
                      }
                    }
                  });
                }
              } else {
                setTimeout(() => {
                  this.prize = true;
                }, 100);
                let options = {
                  id: Number(this.$route.params.id),
                  count: Number(this.status),
                };
                //调用开箱接口
                openBoxAPI(options).then((res) => {
                  this.totalPrice = 0;
                  this.weaponID = [];
                  this.prizes = [];
                  for (var i = 0; i < Number(this.status); i++) {
                    this.prizes.push(res.data.data[i].ttBoxRecord);
                    this.weaponID.push(res.data.data[i].ttBoxRecord.recordId);
                    this.totalPrice += res.data.data[i].ttBoxRecord.opeice;
                  }
                  this.totalPrice = this.totalPrice.toFixed(2);
                });
              }
            } else {
              this.$message.error("金币不足！");
            }
          });
        } else {
          this.$message.error("为防止机器人恶意注册，请充值后再使用此功能");
        }
      });
    },
    //试玩
    demo() {
      this.prizes = [];
      this.$bus.$emit("unshow", false);
      this.cartoon = true;
      //打乱顺序
      while (this.prizes1.length < 60) {
        let b = this.boxcontain.length;
        let a = Math.floor(Math.random() * b);
        this.prizes1.push(this.boxcontain[a]);
      }
      let i = this.prizes1.length;
      while (i) {
        let j = Math.floor(Math.random() * i--);
        [this.prizes1[j], this.prizes1[i]] = [this.prizes1[i], this.prizes1[j]];
      }
      setTimeout(() => {
        this.cartoon = false;
        this.prize = true;
      }, 6000);
      //随机抽奖
      let random = parseInt(Math.random() * this.boxcontain.length);
      // console.log(random);
      this.prizes1[18] = this.boxcontain[random];
      this.totalPrice = this.boxcontain[random].openPrice;
      this.prizes.push(this.boxcontain[random]);
    },
    //一键分解
    async confirm() {
      //一件分解api
      let code = (await allDecomposeAPI(this.weaponID)).data.code;
      if (code == 200) {
        this.$message({
          message: "恭喜你,分解成功",
          type: "success",
        });
        this.$bus.$emit("openbox", 1);
        this.prize = false;
      }
      this.prizes = [];
      this.totalPrice = 0;
      getUserInfo().then((res) => {
        this.userGold = res.data.user.bean;
      });
    },
    //单个分解武器
    decompose(index) {
      let id = this.prizes[index].recordId;
      decomposeWeaponAPI(id).then(() => {
        this.prizes.splice(index, 1);
        this.$bus.$emit("openbox", 1);
        this.$message({
          message: "恭喜你,分解成功",
          type: "success",
          customClass: "messageIndex",
        });
        this.reload();
      });
      if (this.prizes.length == 1) {
        this.prize = false;
        this.$bus.$emit("show", true);
        //更新最近掉落
        let options = Number(this.$route.params.id);
        newFallAPI(options).then((res) => {
          this.recentFall = res.data.data;
        });
      }
    },
    //放入背包
    fang() {
      console.log("执行了1");
      this.prize = false;
      this.$bus.$emit("show", true);
      //更新最近掉落
      let options = Number(this.$route.params.id);
      newFallAPI(options).then((res) => {
        this.recentFall = res.data.data;
      });
      this.$bus.$emit("openbox", 1);
      this.prizes = [];
      this.totalPrice = 0;
      getUserInfo().then((res) => {
        this.userGold = res.data.user.bean;
      });
      console.log("执行了2");
      // this.reload();
    },
    closet() {
      this.prize = false;
      this.$bus.$emit("show", true);
    },
    //动画开关
    show() {
      this.isShow = !this.isShow;
      if (this.how == false) {
        this.how = true;
      }
    },
    //声音开关
    shoW() {
      //this.isPlay = false;
      this.how = !this.how;
    },
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
    getUserInfo().then((res) => {
      this.userGold = res.data.user.bean;
    });
    let options = Number(this.$route.params.id);
    getBoxDetail(options).then((res) => {
      this.weaponTxt = res.data.data.name;
      this.weaponImg = res.data.data.img2;
      this.weaponPrice = res.data.data.price;
      this.values = res.data.data.price;
    });
    //判断用户是否登录
    let token = getToken();
    if (token != undefined) {
      this.isLogin = 2;
    }
    //箱子包含
    boxContain(options).then((res) => {
      this.prizes1 = JSON.parse(JSON.stringify(res.data.rows));
      this.prizes2 = JSON.parse(JSON.stringify(res.data.rows));
      this.prizes3 = JSON.parse(JSON.stringify(res.data.rows));
      this.prizes4 = JSON.parse(JSON.stringify(res.data.rows));
      this.prizes5 = JSON.parse(JSON.stringify(res.data.rows));
      this.boxcontain = res.data.rows;

      function compare(property) {
        return function (obj1, obj2) {
          var value1 = obj1[property];
          var value2 = obj2[property];
          return value2 - value1; // 降序
        };
      }

      this.boxcontain.sort(compare("openPrice"));
    });
    //最近掉落
    newFallAPI(options).then((res) => {
      this.recentFall = res.data.data;
    });
  },
  watch: {
    screenWidth: {
      handler: function (val) {
        if (val <= 550) {
          this.isshow = true;
        } else {
          this.isshow = false;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
// 背景隐藏层
.bg {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 9%;
  z-index: 1;
  backdrop-filter: blur(5px);
}

.openbox {
  .goout {
    img {
      width: 100px;
      height: 100px;
      float: right;
      margin-left: -50px;
    }
  }

  .messageIndex {
    z-index: 3000 !important;
  }

  .openbox-main {
    padding-top: 15px;
    height: 100%;

    .openbox-title {
      @media (max-width: 550px) {
        position: absolute;
        left: 2px;
        top: 184px;
      }

      .closea {
        @media (max-width: 550px) {
          display: flex;
          align-items: center;
          color: #fff;
          font-size: 12px;
        }
      }

      .closem {
        @media (max-width: 550px) {
          display: flex;
          align-items: center;
          color: #fff;
        }

        .closema {
          @media (max-width: 550px) {
            width: 30px;
            height: 30px;
            background-image: url("../../assets/images/openbox/bg1234.png");
            background-size: 100% 100%;
          }

          img {
            @media (max-width: 550px) {
              display: block;
              width: 16px;
              height: 16px;
              margin: 7px auto;
            }
          }
        }

        .closemt {
          @media (max-width: 550px) {
            font-size: 12px;
            margin-left: 2px;
          }
        }
      }
    }

    .gunboxbg {
      width: 420px;
      height: 340px;
      margin: auto;
      background: url("~@/assets/images/openbox/gunboxbg.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
    }

    .openbox-img {
      display: inline-block;
      width: 180px;
      height: auto;
      margin: auto;
      padding-top: 40px;
      animation: gunfloat 3s linear infinite;

      @media (min-width: 450px) {
        width: 280px;
        height: auto;
        padding-top: 0px;
        animation: gunfloat 3s linear infinite;
      }
    }

    @keyframes gunfloat {
      0% {
        margin-top: 0;
      }

      50% {
        margin-top: -40px;
      }

      100% {
        margin-top: 0;
      }
    }

    .boxname {
      width: 140px;
      height: 30px;
      margin: auto;
      margin-bottom: 60px;
      color: #fff;
      background-color: #0a32a1a4;
      text-align: center;
      font-size: 24px;
      border-radius: 10px;
    }

    .amount {
      margin-top: 28px;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      color: #fff;

      div {
        margin-right: 8px;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        background-size: 100%;

        @media (min-width: 450px) {
          width: 60px;
          height: 50px;
          line-height: 50px;
          font-size: 20px;
        }
      }

      .active {
        background-image: url(../../assets/images/openbox/selected.png);
        background-size: 100% 100%;
      }

      .actived {
        background-image: url(../../assets/images/openbox/unselected.png);
        background-size: 100% 100%;
      }
    }

    .open {
      width: 279px;
      height: 82px;
      margin: 0 auto 100px;
      color: #fff;
      font-weight: none;
      text-align: center;
      line-height: 82px;
      font-size: 20px;
      background-image: url(../../assets/images/openbox/open.png);
      background-size: 100% 100%;
    }

    .goods-type {
      width: 533px;
      height: 70px;
      margin: auto;
      line-height: 70px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("~@/assets/images/openbox/goodsbg.png");
      background-size: 100% 100%;

      div {
        width: 247px;
        height: 84px;
        line-height: 84px;
        text-align: center;
        font-weight: none;
        font-size: 18px;
      }

      .boxs {
        background-image: url(../../assets/images/openbox/goods.png);
        background-size: 100% 100%;
        background-position-y: 14px;
      }
    }

    .newfalling {
      width: 85%;
      height: 416px;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      overflow: auto;
      padding-top: 20px;
      background-image: url("~@/assets/images/openbox/gunlistbg.png");

      .fall-header {
        width: 215px;
        height: 136px;
        padding-top: 8px;
        margin: 8px;
        background-size: 100% 100%;

        .fall-item {
          width: 100%;
          height: 16px;
          margin-bottom: 4px;
          display: flex;
          align-items: center;
          color: #fff;
          font-size: 16px;

          img {
            width: 15px;
            height: 15px;

            @media (min-width: 450px) {
              width: 16px;
              height: 16px;
            }
          }
        }

        .fall-img {
          width: 40%;
          height: 54%;
          margin: auto;
          display: block;

          @media (min-width: 450px) {
            height: 66px;
            width: 50%;
          }
        }

        .fall-title {
          width: 160px;
          margin-left: 10px;
          color: #fff;
          font-weight: 400;
          font-size: 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          @media (min-width: 450px) {
            width: 80%;
            margin-left: 18px;
            font-size: 12px;
          }
        }

        .txt {
          margin-top: 6px;
        }
      }
    }

    .open-animation1 {
      position: fixed;
      top: 100px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
      width: 800px;
      height: 600px;
      background-image: url("../../assets/images/openbox/pop.png");
      background-size: 100% 100%;

      @media (max-width: 550px) {
        top: 100px;
        left: 50%;
        width: 100%;
        height: 400px;
        background-image: url("../../assets/images/openbox/obg.png");
      }

      .main {
        width: 90%;
        height: 500px;
        margin: 80px auto;
        display: flex;
        justify-content: space-evenly;

        @media (max-width: 550px) {
          width: 90%;
          height: 330px;
          margin: 50px auto;
        }

        .main-item {
          width: 500px;
          height: 100%;
          overflow: hidden;

          @media (max-width: 550px) {
            width: 49%;
            height: 100%;
          }

          .item-main {
            width: 140px;
            height: 210px;
            padding: 20px;
            box-sizing: border-box;
            background-size: 100% 100%;
            margin: 0px auto 50px;
            text-align: center;
            color: #fff;
            overflow: auto;

            @media (max-width: 550px) {
              width: 100%;
              margin: 0px auto 20px;
            }

            .wh {
              display: flex;
              align-items: center;
              height: 40px;
              width: 100px;
              font-size: 14px;
              margin-top: 5px;
              margin-left: 10px;

              @media (max-width: 550px) {
                height: 30px;
                width: 130px;
              }

              img {
                @media (max-width: 550px) {
                  margin-left: 14px;
                  width: 20px;
                  height: 20px;
                }
              }

              div {
                width: 90px;
                font-size: 12px;

                @media (max-width: 550px) {
                  width: 70px;
                  font-size: 12px;
                }
              }
            }

            .wmi {
              display: block;
              width: 100%;
              height: 60%;
              margin: auto;

              @media (max-width: 550px) {
                width: 100%;
              }
            }

            .iab {
              width: 100%;
              font-size: 12px;
              color: #fff;
              margin: auto;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              @media (max-width: 550px) {
                font-size: 12px;
              }
            }
          }

          .animation1 {
            animation: openbox1 6s cubic-bezier(0.3, 0.5, 0.14, 0.92) 0s 1;
            animation-fill-mode: forwards;

            @media (max-width: 550px) {
              animation: openboxp1 5.5s cubic-bezier(0.3, 0.5, 0.14, 0.92) 0s 1;
              animation-fill-mode: forwards;

              @keyframes openboxp1 {
                0% {
                  transform: translateX(0px);
                }

                100% {
                  transform: translateY(-4050px);
                }
              }
            }

            @keyframes openbox1 {
              0% {
                transform: translateX(0px);
              }

              100% {
                transform: translateY(-4500px);
              }
            }
          }
        }

        .zhizhen1 {
          position: absolute;
          top: 300px;
          left: 40px;
          width: 80px;
          height: 80px;

          @media (max-width: 550px) {
            position: absolute;
            z-index: 222;
            top: 200px;
            left: 0px;
            width: 50px;
            height: 50px;
          }
        }

        .zhizhen2 {
          position: absolute;
          top: 300px;
          right: 40px;
          width: 80px;
          height: 80px;

          @media (max-width: 550px) {
            position: absolute;
            z-index: 222;
            top: 200px;
            right: 0px;
            width: 50px;
            height: 50px;
          }
        }

        .zhizhen3 {
          position: absolute;
          width: 74%;
          height: 10px;
          top: 336px;
          left: 13%;
          z-index: 999;

          @media (max-width: 550px) {
            position: absolute;
            z-index: 222;
            top: 220px;
            left: 41px;
            width: 78%;
            height: 10px;
          }
        }
      }
    }

    .open-animation2 {
      position: fixed;
      top: 100px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
      width: 800px;
      height: 600px;
      background-image: url("../../assets/images/openbox/pop.png");
      background-size: 100% 100%;

      @media (max-width: 550px) {
        top: 100px;
        left: 50%;
        width: 366px;
        height: 400px;
        background-image: url("../../assets/images/openbox/obg.png");
      }

      .main {
        width: 700px;
        height: 500px;
        margin: 80px auto;
        display: flex;
        justify-content: space-evenly;

        @media (max-width: 550px) {
          width: 330px;
          height: 330px;
          margin: 55px auto;
        }

        .zhizhen1 {
          position: absolute;
          top: 300px;
          left: 40px;
          width: 80px;
          height: 80px;

          @media (max-width: 550px) {
            position: absolute;
            z-index: 222;
            top: 200px;
            left: 0px;
            width: 50px;
            height: 50px;
          }
        }

        .zhizhen2 {
          position: absolute;
          top: 300px;
          right: 40px;
          width: 80px;
          height: 80px;

          @media (max-width: 550px) {
            position: absolute;
            z-index: 222;
            top: 200px;
            right: 0px;
            width: 50px;
            height: 50px;
          }
        }

        .zhizhen3 {
          position: absolute;
          width: 74%;
          height: 10px;
          top: 336px;
          left: 13%;
          z-index: 999;

          @media (max-width: 550px) {
            position: absolute;
            z-index: 222;
            top: 220px;
            left: 41px;
            width: 78%;
            height: 10px;
          }
        }

        .main-item {
          width: 160px;
          height: 100%;
          overflow: hidden;

          .item-main {
            width: 100%;
            height: 210px;
            padding: 20px 5px 0;
            box-sizing: border-box;
            background-size: 100% 100%;
            margin: 0px auto 50px;
            text-align: center;
            color: #fff;
            font-weight: 500;
            overflow: hidden;

            @media (max-width: 550px) {
              width: 160px;
              height: 200px;
            }

            .wh {
              display: flex;
              align-items: center;
              height: 30px;
              width: 130px;
              margin-top: 5px;
              margin-left: 10px;

              @media (max-width: 550px) {
                height: 18px;
                width: 80px;
                margin-top: 16px;
                margin-left: 70px;
              }

              img {
                width: 24px;
                height: 12px;

                @media (max-width: 550px) {
                  width: 18px;
                  height: 18px;
                }
              }

              div {
                width: 90px;
                font-size: 20px;

                @media (max-width: 550px) {
                  width: 70px;
                  font-size: 12px;
                }
              }
            }

            .wmi {
              display: block;
              width: 80%;
              height: 60%;
              margin: -10px auto 0px;

              @media (max-width: 550px) {
                width: 140px;
                height: 110px;
                margin: auto;
              }
            }

            .iab {
              width: 80%;
              font-size: 12px;
              color: #fff;
              margin: auto;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              @media (max-width: 550px) {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .open-animation3 {
      position: fixed;
      top: 100px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
      width: 1000px;
      height: 600px;
      background-image: url("../../assets/images/openbox/pop.png");
      background-size: 100% 100%;

      @media (max-width: 550px) {
        top: 100px;
        left: 50%;
        width: 366px;
        height: 400px;
        background-image: url("../../assets/images/openbox/obg.png");
      }

      .main {
        width: 900px;
        height: 500px;
        margin: 80px auto;
        display: flex;
        justify-content: space-evenly;

        @media (max-width: 550px) {
          width: 330px;
          height: 330px;
          margin: 55px auto;
        }

        .main-item {
          width: 180px;
          height: 100%;
          overflow: hidden;

          @media (max-width: 550px) {
            width: 104px;
            height: 330px;
          }

          .item-main {
            width: 100%;
            height: 210px;
            padding: 35px 5px 0;
            box-sizing: border-box;
            background-size: 100% 100%;
            margin: 0px auto 50px;
            text-align: center;
            color: #fff;
            font-weight: 500;
            overflow: hidden;

            @media (max-width: 550px) {
              width: 104px;
              height: 140px;
              margin: 0px auto 20px;
            }

            .wh {
              display: flex;
              align-items: center;
              height: 30px;
              width: 130px;
              font-size: 14px;
              margin-top: 5px;
              margin-left: 10px;

              @media (max-width: 550px) {
                width: 60px;
                height: 18px;
                margin-top: 10px;
                margin-left: 20px;
              }

              img {
                width: 24px;
                height: 24px;

                @media (max-width: 550px) {
                  margin-right: 4px;
                  width: 15px;
                  height: 15px;
                }
              }

              div {
                width: 90px;
                font-size: 12px;

                @media (max-width: 550px) {
                  width: 30px;
                  font-size: 12px;
                }
              }
            }

            .wmi {
              display: block;
              width: 80%;
              height: 60%;
              margin: -10px auto 0px;

              @media (max-width: 550px) {
                width: 80px;
                height: 70px;
                margin: auto;
              }
            }

            .iab {
              width: 80%;
              font-size: 12px;
              color: #fff;
              margin: auto;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              @media (max-width: 550px) {
                font-size: 12px;
                font-weight: 500;
              }
            }
          }
        }

        .zhizhen1 {
          position: absolute;
          top: 300px;
          left: 40px;
          width: 80px;
          height: 80px;

          @media (max-width: 550px) {
            position: absolute;
            z-index: 222;
            top: 200px;
            left: 0px;
            width: 50px;
            height: 50px;
          }
        }

        .zhizhen2 {
          position: absolute;
          top: 300px;
          right: 40px;
          width: 80px;
          height: 80px;

          @media (max-width: 550px) {
            position: absolute;
            z-index: 222;
            top: 200px;
            right: 0px;
            width: 50px;
            height: 50px;
          }
        }

        .zhizhen3 {
          position: absolute;
          width: 80%;
          height: 10px;
          top: 336px;
          left: 10%;
          z-index: 999;

          @media (max-width: 550px) {
            position: absolute;
            z-index: 222;
            top: 220px;
            left: 41px;
            width: 78%;
            height: 10px;
          }
        }
      }
    }

    .open-animation4 {
      position: fixed;
      top: 100px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
      width: 1200px;
      height: 600px;
      background-image: url("../../assets/images/openbox/pop.png");
      background-size: 100% 100%;

      @media (max-width: 550px) {
        top: 100px;
        left: 50%;
        width: 370px;
        height: 400px;
        background-image: url("../../assets/images/openbox/obg.png");
      }

      .main {
        width: 1070px;
        height: 500px;
        margin: 80px auto;
        display: flex;
        justify-content: space-evenly;

        @media (max-width: 550px) {
          width: 340px;
          height: 330px;
          margin: 55px auto;
        }

        .main-item {
          width: 200px;
          height: 100%;
          overflow: hidden;

          @media (max-width: 550px) {
            width: 84px;
            height: 330px;
          }

          .item-main {
            width: 200px;
            height: 280px;
            padding: 25px 10px 0 0px;
            box-sizing: border-box;
            background-size: 100% 100%;
            margin: 0px auto 50px;
            text-align: center;
            color: #fff;
            font-weight: 500;
            overflow: hidden;

            @media (max-width: 550px) {
              width: 84px;
              height: 140px;
              margin: 0px auto 20px;
              //background-color: #fff;
            }

            .wh {
              display: flex;
              align-items: center;
              height: 30px;
              width: 130px;
              font-size: 14px;
              margin-top: 5px;
              margin-left: 10px;

              @media (max-width: 550px) {
                width: 30px;
                height: 18px;
                margin-top: 10px;
                margin-left: 10px;
              }

              img {
                width: 20px;
                height: 20px;

                @media (max-width: 550px) {
                  margin-right: 4px;
                  width: 12px;
                  height: 12px;
                }
              }

              div {
                width: 90px;
                font-size: 12px;

                @media (max-width: 550px) {
                  width: 30px;
                  font-size: 12px;
                }
              }
            }

            .wmi {
              display: block;
              width: 75%;
              height: 60%;
              margin: -10px auto 0px;

              @media (max-width: 550px) {
                width: 75px;
                height: 80px;
                margin: 0px auto -8px;
              }
            }

            .iab {
              width: 80%;
              font-size: 12px;
              color: #fff;
              margin: auto;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              @media (max-width: 550px) {
                font-size: 12px;
                margin: 0px auto;
                font-weight: 500;
              }
            }
          }
        }

        .zhizhen1 {
          position: absolute;
          top: 300px;
          left: 40px;
          width: 80px;
          height: 80px;

          @media (max-width: 550px) {
            position: absolute;
            z-index: 222;
            top: 200px;
            left: 0px;
            width: 40px;
            height: 50px;
          }
        }

        .zhizhen2 {
          position: absolute;
          top: 300px;
          right: 40px;
          width: 80px;
          height: 80px;

          @media (max-width: 550px) {
            position: absolute;
            z-index: 222;
            top: 200px;
            right: 0px;
            width: 40px;
            height: 50px;
          }
        }

        .zhizhen3 {
          position: absolute;
          width: 84%;
          height: 10px;
          top: 336px;
          left: 8%;
          z-index: 999;

          @media (max-width: 550px) {
            position: absolute;
            z-index: 222;
            top: 220px;
            left: 32px;
            width: 83%;
            height: 10px;
          }
        }
      }
    }

    .open-animation5 {
      position: fixed;
      top: 100px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
      width: 1400px;
      height: 600px;
      background-image: url("../../assets/images/openbox/pop.png");
      background-size: 100% 100%;

      @media (max-width: 550px) {
        top: 100px;
        left: 50%;
        width: 366px;
        height: 400px;
        background-image: url("../../assets/images/openbox/obg.png");
      }

      .main {
        width: 1240px;
        height: 500px;
        margin: 80px auto;
        display: flex;
        justify-content: space-evenly;

        @media (max-width: 550px) {
          width: 330px;
          height: 330px;
          margin: 55px auto;
        }

        .main-item {
          width: 200px;
          height: 100%;
          overflow: hidden;

          @media (max-width: 550px) {
            width: 66px;
            height: 330px;
          }

          .item-main {
            width: 200px;
            height: 280px;
            padding: 25px 5px 0;
            box-sizing: border-box;
            background-size: 100% 100%;
            margin: 0px auto 50px;
            text-align: center;
            color: #fff;
            font-weight: 500;
            overflow: hidden;

            @media (max-width: 550px) {
              width: 66px;
              height: 140px;
              margin: 0px auto 20px;
            }

            .wh {
              display: flex;
              justify-content: space-evenly;
              align-items: center;
              height: 30px;
              width: 130px;
              font-size: 14px;
              margin-top: 5px;
              margin-left: 10px;

              @media (max-width: 550px) {
                width: 100%;
                height: 18px;
                margin-top: 10px;
                margin-left: 10px;
              }

              img {
                width: 12px;
                height: 12px;

                @media (max-width: 550px) {
                  width: 12px;
                  height: 12px;
                }
              }

              div {
                width: 90px;
                font-size: 12px;

                @media (max-width: 550px) {
                  font-size: 12px;
                  //width: 20px;
                }
              }
            }

            .wmi {
              display: block;
              width: 80%;
              height: 60%;
              margin: auto;

              @media (max-width: 550px) {
                width: 74%;
                height: 58px;
              }
            }

            .iab {
              width: 80%;
              font-size: 12px;
              color: #fff;
              margin: auto;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              @media (max-width: 550px) {
                font-size: 12px;
                font-weight: 500;
              }
            }
          }
        }

        .zhizhen1 {
          position: absolute;
          top: 300px;
          left: 40px;
          width: 80px;
          height: 80px;

          @media (max-width: 550px) {
            position: absolute;
            z-index: 222;
            top: 200px;
            left: 0px;
            width: 40px;
            height: 50px;
          }
        }

        .zhizhen2 {
          position: absolute;
          top: 300px;
          right: 40px;
          width: 80px;
          height: 80px;

          @media (max-width: 550px) {
            position: absolute;
            z-index: 222;
            top: 200px;
            right: 0px;
            width: 40px;
            height: 50px;
          }
        }

        .zhizhen3 {
          position: absolute;
          width: 86%;
          height: 10px;
          top: 336px;
          left: 7%;
          z-index: 999;

          @media (max-width: 550px) {
            position: absolute;
            z-index: 222;
            top: 220px;
            left: 32px;
            width: 83%;
            height: 10px;
          }
        }
      }
    }

    .congratulation {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      margin: auto;
      z-index: 999;
      background-color: #111111e8;

      @media (max-width: 550px) {
        position: fixed;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        margin: auto;
        height: 400px;
        background: none;
      }

      .c-main {
        width: 1100px;
        height: 582px;
        position: fixed;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        margin: auto;
        background: url("~@/assets/images/openbox/pop.png") no-repeat;
        background-size: 100% 100%;
        z-index: 99;

        @media (max-width: 550px) {
          position: relative;
          width: 100%;
          height: 400px;
          background-image: url("../../assets/images/openbox/obg.png");
          overflow: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 99;
        }

        .cnm {
          padding: 200px 130px;
          display: flex;
          justify-content: space-evenly;
          flex-wrap: wrap;
          overflow: auto;

          @media (max-width: 550px) {
            padding: 10px;
            width: 320px;
            height: 320px;
            margin-top: 30px;
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            overflow: auto;
          }

          .c-item {
            width: 150px;
            height: 206px;
            padding-top: 5px;
            background-size: 100% 100%;
            flex: none;

            @media (max-width: 550px) {
              width: 95px;
              height: 130px;
              padding: 0px;
            }

            .c-item-header {
              display: flex;
              justify-content: space-evenly;
              align-items: center;
              color: white;
              font-size: 12px;
              margin-top: 7px;

              img {
                width: 18px;
                height: 18px;

                @media (max-width: 550px) {
                  width: 12px;
                  height: 12px;
                }
              }
            }

            .c-item-middle {
              width: 130px;
              height: 100px;
              display: block;
              margin: 4px auto;

              @media (max-width: 550px) {
                width: 80%;
                height: 60px;
                display: block;
                margin: 4px auto;
              }
            }

            .c-item-text {
              color: #fff;
              text-align: center;
              font-size: 10px;
              font-weight: 400;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .c-item-title {
              color: white;
              font-size: 10px;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .back {
            width: 150px;
            height: 206px;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

      .c-footer {
        position: fixed;
        bottom: 110px;
        left: 0;
        right: 0;
        width: 300px;
        height: 70px;
        margin: auto;
        color: #fff;
        font-size: 18px;
        display: flex;
        justify-content: space-around;
        z-index: 999;

        @media (max-width: 550px) {
          // top: 460px;
          left: 0px;
          bottom: 0px;
          height: 50px;
          position: absolute;
          z-index: 9999;
        }

        div {
          width: 120px;
          height: 100%;
          text-align: center;
          font-size: 18px;
          line-height: 70px;
          background-image: url(../../assets/images/openbox/getbag.png);
          background-size: 100% 100%;

          @media (max-width: 550px) {
            line-height: 50px;
            background-image: url("../../assets/images/openbox/fangru.png");
          }
        }
      }

      .c-price {
        position: fixed;
        bottom: 200px;
        left: 0;
        right: 0;
        width: 100px;
        height: 40px;
        margin: auto;
        color: rgba(255, 240, 0, 1);
        font-size: 20px;
        text-align: center;
        line-height: 40px;
        font-weight: 600;
        z-index: 99;

        @media (max-width: 550px) {
          position: absolute;
          top: -152px;
          left: 110px;
          font-size: 18px;
          z-index: 99;
        }
      }
    }

    .animation21 {
      animation: openbox21 5s cubic-bezier(0.3, 0.5, 0.14, 1) 0s 1;
      animation-fill-mode: forwards;

      @media (max-width: 550px) {
        animation: openboxp21 5s cubic-bezier(0.3, 0.5, 0.14, 1) 0s 1;
        animation-fill-mode: forwards;

        @keyframes openboxp21 {
          0% {
            transform: translateX(0px);
          }

          100% {
            transform: translateY(-4420px);
          }
        }
      }

      @keyframes openbox21 {
        0% {
          transform: translateX(0px);
        }

        100% {
          transform: translateY(-4500px);
        }
      }
    }

    .animation22 {
      animation: openbox22 6s cubic-bezier(0.3, 0.5, 0.14, 1) 0s 1;
      animation-fill-mode: forwards;

      @media (max-width: 550px) {
        animation: openboxp22 5.5s cubic-bezier(0.3, 0.5, 0.14, 1) 0s 1;
        animation-fill-mode: forwards;

        @keyframes openboxp22 {
          0% {
            transform: translateX(0px);
          }

          100% {
            transform: translateY(-4420px);
          }
        }
      }

      @keyframes openbox22 {
        0% {
          transform: translateX(0px);
        }

        100% {
          transform: translateY(-4500px);
        }
      }
    }

    .animation31 {
      animation: openbox31 5s cubic-bezier(0.3, 0.5, 0.14, 1) 0s 1;
      animation-fill-mode: forwards;

      @media (max-width: 550px) {
        animation: openboxp31 5.5s cubic-bezier(0.1, 0.5, 0.14, 1) 0s 1;
        animation-fill-mode: forwards;

        @keyframes openboxp31 {
          0% {
            transform: translateX(0px);
          }

          100% {
            transform: translateY(-2800px);
          }
        }
      }

      @keyframes openbox31 {
        0% {
          transform: translateX(0px);
        }

        100% {
          transform: translateY(-4540px);
        }
      }
    }

    .animation32 {
      animation: openbox32 5.5s cubic-bezier(0.3, 0.5, 0.14, 1) 0s 1;
      animation-fill-mode: forwards;

      @media (max-width: 550px) {
        animation: openboxp32 5s cubic-bezier(0.1, 0.5, 0.14, 1) 0s 1;
        animation-fill-mode: forwards;

        @keyframes openboxp32 {
          0% {
            transform: translateX(0px);
          }

          100% {
            transform: translateY(-2800px);
          }
        }
      }

      @keyframes openbox32 {
        0% {
          transform: translateX(0px);
        }

        100% {
          transform: translateY(-4540px);
        }
      }
    }

    .animation33 {
      animation: openbox33 6s cubic-bezier(0.3, 0.5, 0.14, 1) 0s 1;
      animation-fill-mode: forwards;

      @media (max-width: 550px) {
        animation: openboxp33 5.2s cubic-bezier(0.1, 0.5, 0.14, 1) 0s 1;
        animation-fill-mode: forwards;

        @keyframes openboxp33 {
          0% {
            transform: translateX(0px);
          }

          100% {
            transform: translateY(-2800px);
          }
        }
      }

      @keyframes openbox33 {
        0% {
          transform: translateX(0px);
        }

        100% {
          transform: translateY(-4540px);
        }
      }
    }

    .animation41 {
      animation: openbox41 6s cubic-bezier(0.2, 0.3, 0.14, 1) 0s 1;
      animation-fill-mode: forwards;

      @media (max-width: 550px) {
        animation: openboxp4 5.4s cubic-bezier(0.2, 0.3, 0.14, 1) 0s 1;
        animation-fill-mode: forwards;

        @keyframes openboxp4 {
          0% {
            transform: translateX(0px);
          }

          100% {
            transform: translateY(-2800px);
          }
        }
      }

      @keyframes openbox41 {
        0% {
          transform: translateX(0px);
        }

        100% {
          transform: translateY(-5800px);
        }
      }
    }

    .animation42 {
      animation: openbox42 5.25s cubic-bezier(0.2, 0.3, 0.14, 1) 0s 1;
      animation-fill-mode: forwards;

      @media (max-width: 550px) {
        animation: openboxp4 5.25s cubic-bezier(0.2, 0.3, 0.14, 1) 0s 1;
        animation-fill-mode: forwards;

        @keyframes openboxp4 {
          0% {
            transform: translateX(0px);
          }

          100% {
            transform: translateY(-2800px);
          }
        }
      }

      @keyframes openbox42 {
        0% {
          transform: translateX(0px);
        }

        100% {
          transform: translateY(-5800px);
        }
      }
    }

    .animation43 {
      animation: openbox43 5.5s cubic-bezier(0.2, 0.3, 0.14, 1) 0s 1;
      animation-fill-mode: forwards;

      @media (max-width: 550px) {
        animation: openboxp43 5s cubic-bezier(0.2, 0.3, 0.14, 1) 0s 1;
        animation-fill-mode: forwards;

        @keyframes openboxp43 {
          0% {
            transform: translateX(0px);
          }

          100% {
            transform: translateY(-2800px);
          }
        }
      }

      @keyframes openbox43 {
        0% {
          transform: translateX(0px);
        }

        100% {
          transform: translateY(-5800px);
        }
      }
    }

    .animation44 {
      animation: openbox44 5.5s cubic-bezier(0.2, 0.3, 0.14, 1) 0s 1;
      animation-fill-mode: forwards;

      @media (max-width: 550px) {
        animation: openboxp44 5.5s cubic-bezier(0.2, 0.3, 0.14, 1) 0s 1;
        animation-fill-mode: forwards;

        @keyframes openboxp44 {
          0% {
            transform: translateX(0px);
          }

          100% {
            transform: translateY(-2800px);
          }
        }
      }

      @keyframes openbox44 {
        0% {
          transform: translateX(0px);
        }

        100% {
          transform: translateY(-5800px);
        }
      }
    }

    .animation51 {
      animation: openbox51 5.2s cubic-bezier(0.2, 0.3, 0.14, 1) 0s 1;
      animation-fill-mode: forwards;

      @media (max-width: 550px) {
        animation: openboxp51 5s cubic-bezier(0.2, 0.3, 0.14, 1) 0s 1;
        animation-fill-mode: forwards;

        @keyframes openboxp51 {
          0% {
            transform: translateX(0px);
          }

          100% {
            transform: translateY(-2800px);
          }
        }
      }

      @keyframes openbox51 {
        0% {
          transform: translateX(0px);
        }

        100% {
          transform: translateY(-5800px);
        }
      }
    }

    .animation52 {
      animation: openbox52 5.4s cubic-bezier(0.2, 0.3, 0.14, 1) 0s 1;
      animation-fill-mode: forwards;

      @media (max-width: 550px) {
        animation: openboxp52 5.2s cubic-bezier(0.2, 0.3, 0.14, 1) 0s 1;
        animation-fill-mode: forwards;

        @keyframes openboxp52 {
          0% {
            transform: translateX(0px);
          }

          100% {
            transform: translateY(-2800px);
          }
        }
      }

      @keyframes openbox52 {
        0% {
          transform: translateX(0px);
        }

        100% {
          transform: translateY(-5800px);
        }
      }
    }

    .animation53 {
      animation: openbox53 5.6s cubic-bezier(0.2, 0.3, 0.14, 1) 0s 1;
      animation-fill-mode: forwards;

      @media (max-width: 550px) {
        animation: openboxp53 5.5s cubic-bezier(0.2, 0.3, 0.14, 1) 0s 1;
        animation-fill-mode: forwards;

        @keyframes openboxp53 {
          0% {
            transform: translateX(0px);
          }

          100% {
            transform: translateY(-2800px);
          }
        }
      }

      @keyframes openbox53 {
        0% {
          transform: translateX(0px);
        }

        100% {
          transform: translateY(-5800px);
        }
      }
    }

    .animation54 {
      animation: openbox54 5.8s cubic-bezier(0.2, 0.3, 0.14, 1) 0s 1;
      animation-fill-mode: forwards;

      @media (max-width: 550px) {
        animation: openboxp54 5.4s cubic-bezier(0.2, 0.3, 0.14, 1) 0s 1;
        animation-fill-mode: forwards;

        @keyframes openboxp54 {
          0% {
            transform: translateX(0px);
          }

          100% {
            transform: translateY(-2800px);
          }
        }
      }

      @keyframes openbox54 {
        0% {
          transform: translateX(0px);
        }

        100% {
          transform: translateY(-5800px);
        }
      }
    }

    .animation55 {
      animation: openbox55 6s cubic-bezier(0.2, 0.3, 0.14, 1) 0s 1;
      animation-fill-mode: forwards;

      @media (max-width: 550px) {
        animation: openboxp55 5.1s cubic-bezier(0.2, 0.3, 0.14, 1) 0s 1;
        animation-fill-mode: forwards;

        @keyframes openboxp55 {
          0% {
            transform: translateX(0px);
          }

          100% {
            transform: translateY(-2800px);
          }
        }
      }

      @keyframes openbox55 {
        0% {
          transform: translateX(0px);
        }

        100% {
          transform: translateY(-5800px);
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .openbox {
    .goout {
      img {
        width: 70px;
        height: 70px;
        float: right;
        margin-left: -50px;
      }
    }

    .messageIndex {
      z-index: 3000 !important;
    }

    .openbox-main {
      padding-top: 15px;
      height: 100%;

      .gunboxbg {
        width: 68%;
        height: 250px;
        margin: auto;
      }

      .boxname {
        width: 140px;
        height: 30px;
        margin: auto;
        margin-bottom: -20px;
        color: #fff;
        background-color: #0d3a7b;
        text-align: center;
        font-size: 24px;
      }

      .amount {
        margin-top: 28px;
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        color: #fff;

        div {
          margin-right: 8px;
          width: 40px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-size: 100%;

          @media (min-width: 450px) {
            width: 60px;
            height: 50px;
            line-height: 50px;
            font-size: 20px;
          }
        }

        .active {
          background-image: url(../../assets/images/openbox/selected.png);
          background-size: 100% 100%;
        }

        .actived {
          background-image: url(../../assets/images/openbox/unselected.png);
          background-size: 100% 100%;
        }
      }

      .open {
        width: 279px;
        height: 82px;
        margin: -36px auto 0px;
        color: #fff;
        text-align: center;
        line-height: 82px;
        font-size: 20px;
        background-image: url(../../assets/images/openbox/open.png);
        background-size: 100% 100%;
      }

      .goods-type {
        width: 100%;
        height: 70px;
        margin: auto;
        line-height: 70px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url("~@/assets/images/openbox/goodsbg.png");
        background-size: 100% 100%;

        div {
          width: 247px;
          height: 84px;
          line-height: 84px;
          text-align: center;
          font-weight: none;
          font-size: 18px;
        }

        .boxs {
          background-image: url(../../assets/images/openbox/goods.png);
          background-size: 100% 100%;
          background-position-y: 14px;
        }
      }

      .newfalling {
        width: 100%;
        height: 460px;
        margin: auto;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        overflow: auto;
        background-image: url("~@/assets/images/openbox/gunlistbg.png");

        .fall-header {
          width: 45%;
          height: 136px;
          padding-top: 8px;
          margin-bottom: 8px;
          background-size: 100% 100%;

          .fall-item {
            width: 100%;
            height: 16px;
            margin-bottom: 4px;
            margin-left: 4px;
            display: flex;
            align-items: center;
            color: #fff;
            font-size: 14px;

            img {
              width: 15px;
              height: 15px;

              @media (min-width: 450px) {
                width: 16px;
                height: 16px;
              }
            }
          }

          .fall-title {
            width: 80%;
            margin-left: 14px;
            color: #fff;
            font-weight: 400;
            font-size: 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            @media (min-width: 450px) {
              width: 80%;
              margin-left: 18px;
              font-size: 12px;
            }
          }

          .txt {
            margin-top: 6px;
          }
        }
      }
    }
  }
}
</style>
